import { useCallback } from 'react'

import { useGqlContext } from '@northvolt/gql'

import { systemsByIdsQuery } from '../systemQueries'

/**
 * this function forces the list of systems to revalidate
 * this does not trigger React Suspense on the list, stale data will be shown
 * until new data arrives
 * This should be called after mutations to the data shown in the list
 * So, for example, calling this is not necessary after mutating the MAC Address
 * because the MAC is not shown in the list
 */
export function useRevalidateSystemsQuery() {
  const { revalidateQuery } = useGqlContext()
  return useCallback(() => {
    return revalidateQuery(systemsByIdsQuery)
  }, [revalidateQuery])
}
