import { faShareNodes } from '@fortawesome/pro-light-svg-icons'

import { Button } from '@northvolt/form'

import { getSptSystemUrl } from '../../utils/getSystemUrl'

export function SptLink({ id, includeTitle }: { id: string; includeTitle: boolean }) {
  const onClick = () => {
    window.open(getSptSystemUrl(id), '_blank')
  }

  return includeTitle ? (
    <Button onClick={onClick} size="small" style="text" suffix={faShareNodes}>
      Product Tree
    </Button>
  ) : (
    <Button infix={faShareNodes} onClick={onClick} size="small" style="text" />
  )
}
