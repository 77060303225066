import clsx from 'clsx'

import { ChargingState } from './types'

export function ChargingNotificationDot({
  chargingState,
}: {
  chargingState?: ChargingState | null
}) {
  return (
    <>
      {chargingState != null && chargingState !== ChargingState.NOT_CONNECTED && (
        <span
          className={clsx('relative inline-flex rounded-full -ml-2 -mr-1 -mt-4 h-3 w-3', {
            'bg-orange-100': chargingState === ChargingState.CLIMATIZING,
            'bg-powerGreen': chargingState === ChargingState.CHARGING,
            'bg-green': chargingState === ChargingState.RELAXING,
            'bg-red-100': chargingState === ChargingState.ERROR,
          })}
        />
      )}
    </>
  )
}
