import { faPen } from '@fortawesome/pro-light-svg-icons'
import { ComponentProps, Suspense, useCallback, useState } from 'react'

import { RolesEnum, useAuthenticationContext } from '@northvolt/auth'
import { Button } from '@northvolt/form'
import { BatterySystemKind } from '@northvolt/gql'
import { BaseModal } from '@northvolt/modal'
import { getSystemTypeDisplayName } from '@northvolt/system-utils'

import { Loader } from '../Loader'

import { EditCore } from './EditCore'
import { EditPack } from './EditPack'
import { EditVoltpackMobileHub } from './EditVoltpackMobileHub'
import { useRevalidateSystemsQuery } from './useRevalidateSystemsQuery'

export function EditModal({
  id,
  kind,
  visible,
  onClose,
}: {
  id: string
  kind?: BatterySystemKind | null
  visible: boolean
  onClose: () => void
}) {
  const title = `Edit ${getSystemTypeDisplayName({ kind, id })}: ${id}`

  const revalidateSystemsQuery = useRevalidateSystemsQuery()
  const baseModalOnClose = useCallback(() => {
    revalidateSystemsQuery()
    onClose()
  }, [onClose, revalidateSystemsQuery])

  return (
    <BaseModal header={title} onClose={baseModalOnClose} visible={visible}>
      <div className="flex w-full flex-col">
        <Suspense fallback={<Loader />}>
          {kind === BatterySystemKind.Pack && <EditPack id={id} />}
          {kind === BatterySystemKind.Subpack && <EditCore id={id} />}
          {kind === BatterySystemKind.Hub && <EditVoltpackMobileHub id={id} />}
        </Suspense>
      </div>
    </BaseModal>
  )
}

export function EditModalButton({
  id,
  includeTitle,
  kind,
  size,
}: {
  id: string
  includeTitle: boolean
  kind?: BatterySystemKind | null
  size?: ComponentProps<typeof Button>['size']
}) {
  const [isEditing, setIsEditing] = useState(false)
  const {
    user: { roles },
  } = useAuthenticationContext()

  if (
    roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_ADMIN) ||
    roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_PRODUCTION) ||
    roles.includes(RolesEnum.BATTERY_DIAGNOSTICS_WRITER) ||
    roles.includes(RolesEnum.ROOT)
  ) {
    return (
      <>
        {includeTitle ? (
          <Button
            onClick={(ev) => {
              ev.stopPropagation()
              ev.preventDefault()
              setIsEditing(true)
            }}
            size={size}
            style="text"
            suffix={faPen}
          >
            Edit
          </Button>
        ) : (
          <Button
            infix={faPen}
            onClick={(ev) => {
              ev.stopPropagation()
              ev.preventDefault()
              setIsEditing(true)
            }}
            size={size}
            style="text"
          />
        )}
        <EditModal
          id={id}
          kind={kind}
          onClose={() => {
            setIsEditing(false)
          }}
          visible={isEditing}
        />
      </>
    )
  }
  return null
}
