import { useCallback, useEffect, useMemo, useState } from 'react'

import { useGqlContext } from './GqlProvider'
import { NvGqlNetworkError, NvGqlResponseError } from './NvGqlError'
import { MutationLoading, MutationResult, QueryType, VariablesType } from './types'

export function useGqlMutation<Result, Variables extends VariablesType>(
  mutation: QueryType | string,
) {
  const { gqlFetcher } = useGqlContext()
  const [result, setResult] = useState<MutationLoading | MutationResult<Result> | null>(null)

  const reset = useCallback(() => {
    setResult(null)
  }, [])

  useEffect(() => {
    setResult(null)
  }, [mutation])

  const mutationFn = useCallback(
    async (variables: Variables): Promise<MutationResult<Result>> => {
      let result: MutationResult<Result>
      try {
        setResult({
          state: 'loading',
        })
        const response = await gqlFetcher(mutation.toString(), variables)
        if (response.errors != null) {
          result = {
            state: 'error',
            // you might get data even if you get errors
            // for example when running multiple mutations in a single request
            data: (response.data as Partial<Result> | null) ?? null,
            errors: response.errors,
          }
        } else {
          result = {
            state: 'success',
            data: response.data as Result,
          }
        }
      } catch (err) {
        if (err instanceof NvGqlNetworkError || Array.isArray(err)) {
          result = {
            state: 'error',
            data: null,
            errors: err as (NvGqlNetworkError | NvGqlResponseError)[],
          }
        } else {
          result = {
            state: 'error',
            data: null,
            errors: [new NvGqlResponseError({ message: String(err) })],
          }
        }
      }
      setResult(result)
      return result
    },
    [mutation, gqlFetcher],
  )

  return useMemo(() => {
    return {
      mutation: mutationFn,
      result,
      reset,
    }
  }, [mutationFn, result, reset])
}
