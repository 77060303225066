import { faSave } from '@fortawesome/pro-regular-svg-icons'
import { useEffect } from 'react'

import { toastError, toastSuccess } from '@northvolt/error-handling'
import {
  Button,
  Dropdown,
  Input,
  InputMultiselect,
  UseFormErrors,
  Validate,
  useForm,
} from '@northvolt/form'
import {
  BatterySystemGeneration,
  CreateVoltpackMobileHubMutation,
  CreateVoltpackMobileHubMutationVariables,
  gql,
  useGqlMutation,
} from '@northvolt/gql'

import { useEntityRelationships } from './useEntityRelationships'

const createVoltpackMobileHubMutation = gql`
  mutation createVoltpackMobileHub(
    $id: ID!
    $vpmHub: CreateVoltpackMobileHubInput!
    $tags: [String!]!
  ) {
    createVoltpackMobileHub(id: $id, voltpackMobileHubDetails: $vpmHub) {
      connectivityId
    }

    setVoltpackMobileHubTags(id: $id, tags: $tags) {
      success
    }
  }
`

type CreateVpmHubData = {
  id: string
  displayName: string
  tenant: null | {
    label: string
    value: string
  }
  // TODO: Remove generation for hubs
  generation: null | {
    label: string
    value: BatterySystemGeneration
  }
  mac: string
  tags: string[]
}

export function CreateVoltpackMobileHub({ onSuccess }: { onSuccess: () => void }) {
  const { mutation, result } = useGqlMutation<
    CreateVoltpackMobileHubMutation,
    CreateVoltpackMobileHubMutationVariables
  >(createVoltpackMobileHubMutation)

  const { tenants, generations, tags } = useEntityRelationships('voltpack-mobile-hub')

  useEffect(() => {
    if (result?.state === 'success') {
      toastSuccess('Voltpack Mobile Hub created successfully', null)
      onSuccess()
    } else if (result?.state === 'error') {
      toastError('Error creating Voltpack Mobile Hub', result.errors)
    }
  }, [result, onSuccess])

  const { getFieldPropsByName, onSubmit, ...state } = useForm<CreateVpmHubData>({
    initialValues: {
      id: '',
      displayName: '',
      tenant:
        tenants.find((v) => {
          return v.value === 'northvolt'
        }) ?? null,
      generation:
        generations.find((v) => {
          return v.value === BatterySystemGeneration.B2
        }) ?? null,
      mac: '',
      tags: [],
    },
    onSubmit: async (formData) => {
      if (document.activeElement && document.activeElement.tagName === 'BUTTON') {
        await mutation({
          id: (formData.id as string).trim(),
          vpmHub: {
            displayName: formData.displayName,
            tenant: formData.tenant?.value as string,
            generation: formData.generation?.value as BatterySystemGeneration,
            macAddress: Validate.getMacAddressFromInput(formData.mac),
          },
          tags: formData.tags as string[],
        })
      }
    },
    onValidate: (values) => {
      const errors: UseFormErrors<CreateVpmHubData> = {}
      if (values.id.length === 0) {
        errors.id = 'Please enter an ID'
      }
      if (values.id.length > 16) {
        errors.id = 'ID cannot be longer than 16 characters'
      }
      if (values.displayName.length === 0) {
        errors.displayName = 'Please enter a display name'
      }
      if (!Validate.testMacAddress(values.mac)) {
        errors.mac = Validate.macAddressAllowedFormats
      }
      return errors
    },
  })

  return (
    <div className="w-full">
      <form onSubmit={onSubmit}>
        <Input className="mb-2" label="ID" required tabIndex={1} {...getFieldPropsByName('id')} />
        <Input
          className="mb-2"
          label="Display name"
          required
          tabIndex={2}
          {...getFieldPropsByName('displayName')}
        />
        <div className="mb-2">
          <Dropdown label="Tenant" list={tenants} tabIndex={3} {...getFieldPropsByName('tenant')} />
        </div>
        <div className="mb-2">
          <Dropdown
            label="Generation"
            list={generations}
            placeholder="Generation"
            tabIndex={4}
            {...getFieldPropsByName('generation')}
          />
        </div>
        <Input
          className="mb-2"
          label="Mac address"
          required
          tabIndex={5}
          {...getFieldPropsByName('mac')}
        />
        <InputMultiselect
          autocomplete={tags}
          className="mb-2"
          label="Tags"
          tabIndex={6}
          {...getFieldPropsByName('tags')}
        />
        <div className="mb-2 flex md:justify-end">
          <Button
            className="w-full md:w-auto"
            disabled={state.formHasErrors || result?.state === 'loading' || state.isSubmitting}
            prefix={faSave}
            style="primary"
            type="submit"
          >
            Create VPM Hub
          </Button>
        </div>
      </form>
    </div>
  )
}
