import { BatterySystemKind, SystemBasicInfoFragment } from '@northvolt/gql'

export function getSystemTypeDisplayName(system?: SystemBasicInfoFragment) {
  switch (system?.kind) {
    case BatterySystemKind.Subpack:
      return system?.state?.position != null ? `Core #${system.state.position}` : 'Core'

    case BatterySystemKind.Pack:
      if (system.id.startsWith('VPM-')) {
        return 'Mobile Voltpack'
      }
      return 'Voltpack'
    case BatterySystemKind.Hub:
      return 'Volthub'
    default:
      return 'Unknown type'
  }
}
