import { gql } from '@northvolt/gql'

export const StateOfCharge_fragment = gql`
  fragment StateOfCharge on BatterySystem {
    state {
      operationalStateOfCharge
      stateOfCharge
    }
    parentSystem {
      state {
        operationalStateOfCharge
      }
    }
  }
`

export const SystemBasicInfo_fragment = gql`
  fragment SystemBasicInfo on BatterySystem {
    id
    connectivityId
    displayName
    kind
    observed
    state {
      position
      isContactorSohErrorDisabled
    }
  }
`
