export const localOverrides: {
  /**
   * set to, for example "[fd3b:d3b5:576:2:b827:eb01:bd6b:0]"
   * to initialize the system in local-ui mode pointing to that IP
   * do not commit this
   */
  offlineOverride: string | null

  /**
   * makes the system point to a specific environment for GQL API requests
   * only used if offlineOverride is null
   */
  apiOverride: 'dev' | 'prod' | 'local' | 'batcloud'
} = {
  offlineOverride: null,
  apiOverride: 'prod',
}
