import { StateOfChargeFragment } from '@northvolt/gql'

/**
 * From a system point of view, we have two different SoC values available
 * dependent on product type and system hierarchy:
 *
 * (Nominal) State of Charge
 * GQL: system.state.stateOfCharge
 * Telemetry: system.state_of_charge (Pack), string.state_of_charge (Core)
 * Description: The nominal state of charge of a system is the true state of charge available
 * in the system. It will be reported on both hub, pack and core level of a system.
 *
 * Operational State of Charge
 * GQL: system.state.operationalStateOfCharge
 * Telemetry: system.operational_state_of_charge (Pack)
 * Description: The operational state of charge of a system is the amount of SoC available in a
 * system based on (optional) scaling rules set in the system. For instance, it is possible to
 * to set a percentage of true/nominal SoC that should be made available in a system. Example:
 * If a system is configured to make available 80% of true SoC, and the true SoC of a system is
 * 50%, the operational state of charge for that system would be represented as 62.5% (0.5/0.8).
 *
 * Note that operational state of charge as a derived value is only available on pack or hub
 * level of system hierarchies, which introduces the need for some contrived logic to hide non-
 * scaled values in other levels of a system hierarchy (in the case of hub, hide true SoC on
 * pack/core level if scaling is set in the hub, on Voltpack products, hide true SoC on cores
 * if scaling has been set in the pack).
 *
 * This function will return boolean values to determine which state of charge values
 * should be displayed to the user.
 */

export const getStateOfChargeVisibility = (
  system?: StateOfChargeFragment,
  isPowerUser?: boolean,
) => {
  // Always display operational state of charge if present in the system
  const shouldDisplayOperationalSoC = system?.state?.operationalStateOfCharge != null

  // Always display nominal state of charge for power users.
  // For other users, never display nominal state of charge if a scaling is present anywhere
  // in the system (which is indicate by the presence of operationalStateOfCharge)
  // TODO: This logic will fail to hide nominal SoC on VPM's in a VHG/VPM/Core hierarchy. Validate
  // sanity...
  const shouldDisplayNominalSoC =
    isPowerUser ||
    (system?.state?.operationalStateOfCharge == null &&
      system?.parentSystem?.state?.operationalStateOfCharge == null)

  return {
    shouldDisplayOperationalSoC,
    shouldDisplayNominalSoC,
  }
}
