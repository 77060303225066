import { Listbox } from '@headlessui/react'
import clsx from 'clsx'
import { ForwardedRef, forwardRef } from 'react'

import { useSizeScreen } from '../useSizeScreen'

import { BoxElementContainer } from './BoxElementContainer'
import { getBoxElementTextSizeClassName } from './getBoxElementTextSizeClassName'
import { BoxButtonProps } from './types'

function BoxButtonElementInner(
  {
    name,
    size,
    error,
    disabled,
    placeholder,
    value,
    state,
    prefix,
    suffix,
    outline,
    tabIndex,
    onBlur,
    onFocus,
  }: BoxButtonProps,
  forwardedRef?: ForwardedRef<HTMLDivElement>,
) {
  const sizeScreen = useSizeScreen(size)
  const loadingOrDisabled = state === 'loading' || disabled

  const className = clsx(
    // rounded here is necessary to prevent the input from overflowing into the
    // border radius of its parent
    'peer outline-none border-none rounded flex flex-1 w-full',
    getBoxElementTextSizeClassName(sizeScreen),
    {
      'text-gray-400': value == null, // placeholder is visible
      'text-gray-600': value != null && loadingOrDisabled,
      'text-black': value != null && !loadingOrDisabled,
      'cursor-pointer': !loadingOrDisabled,
      'cursor-not-allowed': loadingOrDisabled,
      'pl-4': prefix == null,
      'pr-4': suffix == null,
    },
  )

  return (
    <BoxElementContainer
      as="Listbox"
      disabled={loadingOrDisabled ?? false}
      error={error}
      outline={outline}
      prefix={prefix}
      ref={forwardedRef}
      size={size}
      state={state}
      suffix={suffix}
    >
      <Listbox.Button
        className={className}
        name={name}
        onBlur={onBlur ?? undefined}
        onFocus={onFocus ?? undefined}
        tabIndex={tabIndex ?? undefined}
      >
        {(() => {
          const text = value ?? placeholder
          if (text === '' || text == null) {
            // makes the element have the correct height when there is no text
            return '\u200B'
          }
          return text
        })()}
      </Listbox.Button>
    </BoxElementContainer>
  )
}

export const BoxButtonElement = forwardRef(BoxButtonElementInner)
