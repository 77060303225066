import { QueryType } from './types'

class GqlQueryType implements QueryType {
  public body: string
  public fragments: string[]
  private _stringForm: string | null = null

  constructor(body: string, fragments: (string | QueryType)[]) {
    this.body = body.trim()
    this.fragments = []
    for (const fragment of fragments) {
      if (fragment instanceof GqlQueryType) {
        this.fragments = [...this.fragments, fragment.body, ...fragment.fragments]
      } else {
        this.fragments.push((fragment as string).trim())
      }
    }
  }

  toString() {
    if (this._stringForm !== null) {
      // prevents calculating the final string form of this query more than once
      return this._stringForm
    }
    this._stringForm = this.body

    // removes duplicate fragments
    const uniqueFragments = new Set(this.fragments)

    for (const fragment of uniqueFragments) {
      this._stringForm += `\n${fragment}`
    }
    return this._stringForm
  }
}

/**
 * tagged template literal that behaves like "graphql-tag", but is simpler, smaller and faster
 * we don't use "graphql-tag" dependency because we don't want to actually parse the queries/fragments at runtime. We don't want to import the "graphql" dependency at runtime
 * this assumes all ${} expressions are fragments and they must be at the end of the query string
 * if you need to dynamically build a query you should use plain strings instead
 */
export function gql(strings: TemplateStringsArray, ...keys: (string | QueryType)[]): QueryType {
  return new GqlQueryType(strings.join(''), keys)
}
