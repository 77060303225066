import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBoxes, faDatabase, faGrid, faUserCog } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment } from 'react'

import { config } from '@northvolt/config'

import { HeaderProps, RolesEnum } from '../types'

type AppDropDownProps = Pick<HeaderProps, 'theme' | 'user'>

type MenuOptionProps = {
  title: string
  subtitle?: string
  disabled?: boolean
  icon: IconProp
}

function MenuItem({ title, subtitle, disabled, icon }: MenuOptionProps) {
  return (
    <Menu.Item disabled={disabled}>
      <button
        className={clsx('group flex rounded items-center p-2 w-full', {
          'opacity-80': disabled,
          'cursor-default': disabled,
          'hover:bg-gray-200': !disabled,
        })}
      >
        <div className="mr-3 rounded-full h-7 w-7 flex items-center justify-center text-white bg-NvTeal-300">
          <FontAwesomeIcon className="h-3.5 w-3.5 text-white" icon={icon} />
        </div>
        <div className="text-left font-semibold text-black">
          {title}
          {subtitle ? <div className="text-left text-sm text-gray-800">{subtitle}</div> : null}
        </div>
      </button>
    </Menu.Item>
  )
}

function AppOption(props: MenuOptionProps & { url: string }) {
  return (
    <a href={props.url}>
      <MenuItem {...props} />
    </a>
  )
}

export function AppsDropDown({ user, theme }: AppDropDownProps) {
  const portalIsEnabled =
    user.tenant !== 'epiroc'
      ? true
      : user.roles?.includes(RolesEnum.BATTERY_DIAGNOSTICS_PRODUCTION) ||
        user.roles?.includes(RolesEnum.BATTERY_DIAGNOSTICS_ADMIN)

  return (
    <Menu as="div" className="relative inline-block text-left align-middle">
      <Menu.Button className="mr-1 p-2 group flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <FontAwesomeIcon
          className={`${
            theme === 'light'
              ? 'text-gray-500 group-hover:text-gray-600'
              : 'text-gray-400 group-hover:text-gray-300'
          } h-7 w-7 transition`}
          icon={faGrid}
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-30 p-3 absolute right-0 w-52 my-5 origin-top-right divide-y divide-gray-100 bg-white rounded-lg shadow-lg border border-solid border-black border-opacity-5 focus:outline-none">
          <AppOption icon={faBoxes} title="Inventory" url={config.REACT_APP_BASE_INVENTORY_URL} />
          {portalIsEnabled && (
            <AppOption
              icon={faUserCog}
              title="Software &amp; Docs"
              url={config.REACT_APP_BASE_PORTAL_URL}
            />
          )}
          {user.tenant === 'northvolt' && (
            <AppOption
              icon={faDatabase}
              title="Datalake Docs"
              url={config.REACT_APP_BASE_DATALAKE_DOCS_URL}
            />
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
