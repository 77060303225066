import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToastContainer as ToastifyContainer } from 'react-toastify'

export function ToastContainer() {
  return (
    <ToastifyContainer
      className="mt-24"
      closeButton={() => <FontAwesomeIcon className="text-black hover:opacity-70" icon={faTimes} />}
    />
  )
}
