export enum ConnectivityState {
  ONLINE = 'Online',
  RECENTLY_ONLINE = 'RecentlyOnline',
  OFFLINE = 'Offline',
  OFFLINE_LONG_TIME = 'OfflineLongTime',
  UNKNOWN = 'Unknown',
  LOCAL = 'Local',
}

export type ConnectivityInformation = {
  state: ConnectivityState
  lastSeen: string | null
}
