/**
 * Abstract class used as a basis of any manually handled error in our applications
 * The message passed to instances of this class should be user-friendly
 */
export abstract class NvError extends Error {
  public abstract name: string
  /**
   * if this error should be reported
   */
  public abstract shouldReportError: boolean | (() => boolean)

  /**
   * extra information to be added to the error reporting
   */
  public abstract getReportExtra: null | (() => Record<string, any>)

  public cause: unknown

  constructor(
    /** user-friendly message, will be shown to users */
    message: string,
    /**
     * the original cause of this error, usually the error from a "catch" block
     * if there is no error cause, use null as this value
     */
    cause: unknown,
  ) {
    super(message)
    this.cause = cause
  }
}

/**
 * Class for any manually handled errors in our applications
 * this class extends NvError so the message should be user-friendly
 * If you need more complex handling for the error it is advised to instead create
 * a new class that extends NvError instead
 * For examples see @northvolt/gql NvGqlResponseError and NvGqlNetworkError
 */
export class NvGenericError extends NvError {
  public name = 'NvGenericError'
  public shouldReportError = true

  public getReportExtra = () => {
    return this._extra
  }

  private _extra: Record<string, any>

  constructor(
    /** user-friendly message, will be shown to users */
    message: string,
    /**
     * the original cause of this error, usually the error from a "catch" block
     * if there is no error cause, use null as this value
     */
    cause: unknown,
    /** extra data to send while reporting the error */
    extra?: Record<string, any>,
  ) {
    super(message, cause)
    this._extra = extra ?? {}
  }
}
