import fastDeepEqual from 'fast-deep-equal'

// we want to be able to deserialize SWR keys in order to invalidate all queries for any given
// variables object
// when SWR receives an array as key it actually hashes the values which makes it non-deserializable
// see:
// https://github.com/vercel/swr/blob/4d65099f373226c3ed4b9f6818eedfe7d1c519b0/_internal/utils/serialize.ts#L24-L25
// therefor this function MUST return a string
export function serializeSwrKey(query: string, variables: Record<string, any>): string {
  return JSON.stringify([query, variables])
}

export function deserializeSwrKey(key: string): {
  query: string
  variables: Record<string, any>
} {
  const [query, variables] = JSON.parse(key)
  return { query, variables }
}

/**
 * returns true if the provided SWR key matches the provided graphql query
 * query must be provided in string form (call .toString() on QueryType first)
 * if variables is not provided then will return true for any key that matches the query
 * no matter what variables that key may contain
 */
export function keyMatchesQuery(key: string, query: string, variables?: Record<string, any>) {
  const { query: keyQuery, variables: keyVariables } = deserializeSwrKey(key)
  if (query === keyQuery) {
    if (variables == null || fastDeepEqual(variables, keyVariables)) {
      return true
    }
  }
  return false
}
