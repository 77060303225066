import { faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

import blackLogo from '../images/NV_logo_black.png'
import whiteLogo from '../images/NV_logo_white.png'
import { HeaderProps } from '../types'

export function Footer({
  theme = 'dark',
  transparent,
}: {
  theme?: HeaderProps['theme']
  transparent?: boolean
}) {
  return (
    <div
      className={clsx('w-full p-8 md:p-0 mt-auto', {
        'text-black': theme === 'light',
        'text-white': theme === 'dark',
        'bg-transparent': transparent,
        'bg-white': !transparent && theme === 'light',
        'bg-black': !transparent && theme === 'dark',
      })}
    >
      <div className="select-none mx-auto flex flex-wrap justify-between w-full md:max-w-3/4 whitespace-nowrap">
        <div className="px-8 md:px-0 justify-between my-10 align-center w-3/5">
          <img className="pb-4 md:pb-8 max-w-32" src={theme === 'light' ? blackLogo : whiteLogo} />
          <p className="text-sm pb-2 max-w-80 whitespace-normal">
            Developed and maintained by the Connected Battery team at Northvolt ⌁ Stockholm, Sweden.
          </p>
          <a
            className="transition ease-in duration-200 border-b-2 hover:text-powerGreen hover:border-transparent border-powerGreen mr-4"
            href="mailto:bss@northvolt.com"
          >
            bss@northvolt.com
          </a>
        </div>

        <div className="h-auto my-10">
          <button
            className="transition ease-in duration-300 w-8 h-8 md:w-14 md:h-14 bg-powerGreen border-2 border-powerGreen text-white hover:bg-transparent hover:text-powerGreen focus:outline-none"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        </div>
      </div>
    </div>
  )
}
