import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { ChargingNotificationDot } from '@northvolt/charging'
import { SystemsMigrationNotification } from '@northvolt/systems-migration-notification'

import blackLogo from '../images/NV_logo_black.png'
import whiteLogo from '../images/NV_logo_white.png'
import { HeaderProps } from '../types'

import { AppsDropDown } from './AppsDropDown'
import { ProfileDropDown } from './ProfileDropDown'

const mediaMatch = window.matchMedia('(min-width: 1280px)')

export function Header({
  environment,
  user,
  menu,
  title,
  appName,
  appNameShort,
  version,
  /*
   * Select "light" explicitly if "transparent" is true and its background has relatively light color
   * since this theme props determines how buttons should behave for accessibility when they are hoverd.
   * "dark" and "offBlack" have the same hover effect.
   */
  theme = 'dark',
  displayMode = 'default',
  showNotificationDot,
  titleLink,
  transparent,
  align = 'left',
  onMenu,
  chargingState,
  withAppsDropdown = true,
  showVersionInTitle = false,
}: HeaderProps) {
  const [largeScreen, setLargeScreen] = useState(mediaMatch.matches)

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => {
      setLargeScreen(e.matches)
    }
    mediaMatch.addEventListener('change', handler)
    return () => {
      mediaMatch.removeEventListener('change', handler)
    }
  }, [])

  let titleContent = (
    <div
      className={clsx('flex space-between relative text-base items-center h-11', {
        'text-white': theme !== 'light',
        'text-black': theme === 'light',
      })}
    >
      {largeScreen ? (
        <>
          <div
            className={clsx('flex-col text-left content-start border-r pr-8 pt-1', {
              'border-white': theme !== 'light',
              'border-black': theme === 'light',
            })}
          >
            <img
              alt="Northvolt logo"
              className="text-left h-4"
              src={theme === 'light' ? blackLogo : whiteLogo}
            />
            <div className="flex justify-between">
              <span>{title}</span>
            </div>
          </div>
          <div>
            <span className="block pl-8">{appName}</span>
            {showVersionInTitle && <span className="block pl-8 text-sm">{version}</span>}
          </div>
        </>
      ) : (
        <div className="flex-col text-left content-start text-base pt-1">
          <img
            alt="Northvolt logo"
            className="text-left h-4"
            src={theme === 'light' ? blackLogo : whiteLogo}
          />
          <div className="flex items-center">
            <span>{title}</span>
            <span
              className={clsx('flex border-l mx-3 h-3.5', {
                'border-white': theme !== 'light',
                'border-black': theme === 'light',
              })}
            />
            <span>
              {appNameShort ? appNameShort : appName}
              {showVersionInTitle && <span className="ml-2 text-sm">{version}</span>}
            </span>
          </div>
        </div>
      )}
    </div>
  )
  if (titleLink != null) {
    if (typeof titleLink === 'string') {
      titleContent = (
        <Link className="flex" to={titleLink}>
          {titleContent}
        </Link>
      )
    } else if (titleLink.externalLink === true) {
      titleContent = (
        <a className="flex" href={titleLink.link}>
          {titleContent}
        </a>
      )
    } else {
      titleContent = (
        <Link className="flex" to={titleLink.link}>
          {titleContent}
        </Link>
      )
    }
  }

  return (
    <nav
      className={clsx('select-none flex justify-center w-full mx-auto flex-col items-center', {
        'absolute w-full top-0 z-10': displayMode === 'presenter' || displayMode === 'absolute',
        'sticky top-0 z-40': displayMode === 'fixed',
        'bg-transparent': transparent,
        'bg-white border-b border-gray-300': !transparent && theme === 'light',
        'bg-black shadow-[0_1px_3px_0px_rgb(0,0,0,0.3)]': !transparent && theme === 'dark',
        'bg-black-light shadow-[0_1px_3px_0px_rgb(0,0,0,0.3)]':
          !transparent && theme === 'offBlack',
      })}
    >
      <div
        className={clsx('flex flex-wrap w-full justify-between align-center my-3.5 md:my-4', {
          'px-8 xl:px-0 xl:max-w-3/4': align === 'responsive',
          'px-4 md:px-8': align === 'left',
        })}
      >
        <div className="flex items-center">
          {!largeScreen && onMenu && (
            <div
              className="flex justify-center items-center mr-2 md:mr-5 -ml-2.5 p-2.5 cursor-pointer group transition"
              onClick={onMenu}
            >
              <FontAwesomeIcon
                className={`${
                  theme === 'light'
                    ? 'text-gray-500 group-hover:text-gray-600'
                    : 'text-gray-400 group-hover:text-gray-300'
                } h-6 w-6`}
                icon={faBars}
              />
              <ChargingNotificationDot chargingState={chargingState} />
            </div>
          )}
          {titleContent}
          <SystemsMigrationNotification />
        </div>
        {displayMode === 'presenter' ? null : (
          <div className="flex items-center align-center pt-0">
            {withAppsDropdown && <AppsDropDown theme={theme} user={user} />}
            <ProfileDropDown
              environment={environment}
              menu={menu}
              showNotificationDot={showNotificationDot}
              theme={theme}
              user={user}
              version={version}
            />
          </div>
        )}
      </div>
    </nav>
  )
}
