import clsx from 'clsx'
import { ComponentProps, ReactNode } from 'react'

type TableHeadCellProps = {
  children?: ReactNode
  verticalPadding?: 'none' | 'small' | 'medium'
  className?: string
} & ComponentProps<'th'>

export function TableHeadCell({
  children,
  verticalPadding,
  className,
  ...restProps
}: TableHeadCellProps) {
  return (
    <th
      className={clsx(
        'text-base text-left font-medium px-4',
        {
          'py-0': verticalPadding === 'none',
          'py-2': verticalPadding === 'small' || verticalPadding == null,
          'py-4': verticalPadding === 'medium',
        },
        className,
      )}
      {...restProps}
    >
      {children}
    </th>
  )
}
