import { gql } from '@northvolt/gql'
import {
  LowestAcoVersion_fragment,
  StateOfCharge_fragment,
  SystemBasicInfo_fragment,
} from '@northvolt/system-utils'

// TODO: Rename LowestAcoVersion fragment to SoftwareVersions since it is not only
// used for determining the lowest ACO version in a system.
export const System_fragment = gql`
  fragment System on BatterySystem {
    ...LowestAcoVersion
    ...StateOfCharge
    ...SystemBasicInfo
    id
    kind
    displayName
    tags
    observed

    meta {
      tenant
    }
    computers {
      id
    }
  }

  ${LowestAcoVersion_fragment}
  ${SystemBasicInfo_fragment}
  ${StateOfCharge_fragment}
`

export const systemsListQuery = gql`
  query SystemsList($filter: BatteryFilter!) {
    systems(filter: $filter) {
      items {
        id
      }
    }
  }
`

export const systemsByIdsQuery = gql`
  query SystemsByIds($ids: [String!]!) {
    systemsByIDs(ids: $ids) {
      items {
        ...System
      }
    }
  }

  ${System_fragment}
`
