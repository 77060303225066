import { differenceInDays, differenceInHours, differenceInMinutes, formatDistance } from 'date-fns'

import { ConnectivityInformation, ConnectivityState } from './types'

export function getConnectivityInformation(lastObserved: Date | null): ConnectivityInformation {
  const now = new Date()
  const lastSeen =
    lastObserved == null ? null : formatDistance(lastObserved, now, { addSuffix: true })
  if (lastObserved == null) {
    return { state: ConnectivityState.UNKNOWN, lastSeen }
  }
  const minutesSinceSeen = differenceInMinutes(now, lastObserved)

  if (minutesSinceSeen <= 2) {
    return { state: ConnectivityState.ONLINE, lastSeen }
  }
  const hoursSinceSeen = differenceInHours(now, lastObserved)

  if (hoursSinceSeen <= 24) {
    return { state: ConnectivityState.RECENTLY_ONLINE, lastSeen }
  }
  const daysSinceSeen = differenceInDays(now, lastObserved)

  if (daysSinceSeen <= 14) {
    return { state: ConnectivityState.OFFLINE, lastSeen }
  }
  return { state: ConnectivityState.OFFLINE_LONG_TIME, lastSeen }
}
