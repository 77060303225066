import {
  faCopy,
  faHistory,
  faPaste,
  faSignOutAlt,
  faSliders,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { ComponentProps, useMemo } from 'react'

import { config } from '@northvolt/config'

import { AuthenticationProvider, useAuthenticationContext } from './AuthenticationProvider'
import { Footer } from './Footer'
import { Header } from './Header'
import { HeaderProps, RolesEnum } from './types'

type HeaderDefaultMenuProps = Omit<HeaderProps, 'environment' | 'user' | 'version'>

type AuthenticationProviderProps = ComponentProps<typeof AuthenticationProvider>

type AuthenticationWrapperProviderProps = AuthenticationProviderProps & {
  header: null | Omit<HeaderDefaultMenuProps, 'config'>
  footer: null | ComponentProps<typeof Footer>
}

export function AuthenticationWrapperProvider({
  loading,
  children,
  mock,
  header,
  footer,
}: AuthenticationWrapperProviderProps) {
  return (
    <AuthenticationProvider loading={loading} mock={mock}>
      {header != null && <HeaderDefaultMenu {...header} />}
      <div
        className={clsx({
          // prevents the footer from being in the middle of the screen when
          // a page has no content in the middle (for example while loading the list
          // of devices in the inventory)
          'min-h-[calc(100vh-70px)]':
            header !== null && header?.displayMode !== 'absolute' && footer !== null,
          'min-h-[calc(100vh)]':
            (header == null || header?.displayMode === 'absolute') && footer !== null,
        })}
      >
        {children}
      </div>
      {footer != null && <Footer {...footer} />}
    </AuthenticationProvider>
  )
}

export function HeaderDefaultMenu({ menu, ...headerProps }: HeaderDefaultMenuProps) {
  const {
    user,
    copyTokenToClipboard,
    overrideTokenFromClipboard,
    clearOverrideToken,
    tokenOverrideStatus,
    logout,
  } = useAuthenticationContext()

  const menuWithAuth = useMemo(() => {
    const menuItems = [...(menu ?? [])]
    menuItems.push({
      type: 'section',
      title: 'General',
    })

    menuItems.push({
      type: 'link',
      title: 'Releases',
      icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faHistory} />,
      externalLink: true,
      link: `${config.REACT_APP_BASE_PORTAL_URL}/software/`,
    })
    menuItems.push({
      type: 'item',
      title: 'Logout',
      icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faSignOutAlt} />,
      onClick: logout,
    })

    if (
      user.roles.some(
        (role: RolesEnum) =>
          role === RolesEnum.BATTERY_DIAGNOSTICS_ADMIN || role === RolesEnum.ROOT,
      )
    ) {
      menuItems.push({
        type: 'link',
        title: 'Parameters',
        icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faSliders} />,
        externalLink: true,
        link: `${config.REACT_APP_BASE_BATTERY_SYSTEM_URL}/parameters/`,
      })
    }
    if (user.tenant === 'northvolt') {
      menuItems.push({
        type: 'section',
        title: 'Developer utilities',
      })
    }
    if (
      config.REACT_APP_ENVIRONMENT === 'localhost' ||
      user.tenant === 'northvolt' ||
      user.tenant === 'bkk'
    ) {
      menuItems.push({
        type: 'item',
        title: 'Copy Token',
        icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faCopy} />,
        onClick: copyTokenToClipboard,
      })
    }

    if (config.REACT_APP_ENVIRONMENT === 'localhost') {
      menuItems.push({
        type: 'item',
        title: 'Paste Token',
        icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faPaste} />,
        onClick: overrideTokenFromClipboard,
      })
      if (tokenOverrideStatus) {
        menuItems.push({
          type: 'item',
          title: 'Clear Token',
          icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faTrash} />,
          onClick: clearOverrideToken,
        })
      }
    }

    return menuItems
  }, [
    user.roles,
    user.tenant,
    menu,
    copyTokenToClipboard,
    overrideTokenFromClipboard,
    clearOverrideToken,
    tokenOverrideStatus,
    logout,
  ])

  return (
    <Header
      {...headerProps}
      environment={config.REACT_APP_ENVIRONMENT}
      menu={menuWithAuth}
      user={user}
      version={config.REACT_APP_VERSION}
    />
  )
}
