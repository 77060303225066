import { Combobox, Listbox } from '@headlessui/react'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { getBoxElementTextSizeClassName } from '../Box'
import { SizeValue } from '../useSizeScreen'

import { OptionsItem } from './types'

export type BoxOptionProps = {
  as: 'Listbox' | 'Combobox'
  selected: boolean
  children: ReactNode
  disabled?: boolean
  item: OptionsItem | null
  size: SizeValue
  onClick?: (item: OptionsItem | null) => void
  className?: string
}

export function Option({
  as,
  children,
  disabled,
  selected,
  item,
  onClick,
  size,
  className,
}: BoxOptionProps) {
  const Option = as === 'Combobox' ? Combobox.Option : Listbox.Option

  return (
    <Option
      className={({ active }: { active: boolean }) => {
        // active is true if the user is selecting this option with the keyboard
        // or hovering it with the mouse
        return clsx(
          className,
          'text-left py-2 px-4 last:pb-4 w-full',
          getBoxElementTextSizeClassName(size),
          {
            'bg-white cursor-pointer text-black': !active && !selected && !disabled,
            'bg-NvTeal-50 cursor-pointer text-black': !active && selected && !disabled,
            'bg-gray-100 cursor-pointer text-black': active && !selected && !disabled,
            'bg-NvGreen-50 cursor-pointer text-black': active && selected && !disabled,
            'bg-white text-gray-700 cursor-default': disabled,
          },
        )
      }}
      onClick={(event: any) => {
        if (!disabled) {
          onClick?.(item)
        } else {
          event.preventDefault()
        }
      }}
      value={item}
    >
      {children}
    </Option>
  )
}
