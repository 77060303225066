import { RolesEnum, useAuthenticationContext } from '@northvolt/auth'
import {
  ConnectivityInformationBadge,
  getSystemConnectivityInformation,
} from '@northvolt/connectivity-state'
import { DcirStatusBadge } from '@northvolt/dcir'
import { BatterySystemKind, SystemFragment } from '@northvolt/gql'
import { Charge, compareSoftwareVersions, getStateOfCharge } from '@northvolt/system-utils'
import { Tenant } from '@northvolt/tenant-metadata'

import { getSystemImage } from '../../utils/getSystemImage'
import { getSystemUrl } from '../../utils/getSystemUrl'
import { hasSptIntegration } from '../../utils/hasSptIntegration'
import { EditModalButton } from '../Management'
import { saveRecent } from '../RecentlyViewed/recentStorage'

import { Connect } from './Connect'
import { SptLink } from './SptLink'

type SystemCardProps = {
  system: SystemFragment
}

export function SystemCard({ system }: SystemCardProps) {
  const {
    hasRole,
    user: { tenant },
  } = useAuthenticationContext()

  const isPowerUser =
    hasRole([
      RolesEnum.ROOT,
      RolesEnum.BATTERY_DIAGNOSTICS_PRODUCTION,
      RolesEnum.BATTERY_DIAGNOSTICS_ADMIN,
      RolesEnum.BATTERY_DIAGNOSTICS_WRITER,
    ]) || tenant === 'northvolt'

  const isAdminUser =
    hasRole([RolesEnum.ROOT, RolesEnum.BATTERY_DIAGNOSTICS_ADMIN]) || tenant === 'northvolt'

  const isSuperAdminUser =
    hasRole([RolesEnum.ROOT, RolesEnum.BATTERY_DIAGNOSTICS_ADMIN]) && tenant === 'northvolt'

  const spiderVersion: string = (system.state?.softwareVersions ?? []).reduce((prev, curr) => {
    if (curr.software === 'Spider') {
      return curr.version
    }
    return prev
  }, '0.0.0')

  const shouldDisplayRemoteAccessLink =
    compareSoftwareVersions('1.6.0', spiderVersion) <= 0 && isSuperAdminUser

  return (
    <div className="mb-3 shadow-md w-full relative select-none">
      <a
        href={getSystemUrl(system.id)}
        onClick={() => {
          saveRecent(system, system.kind === BatterySystemKind.Subpack ? 'cores' : 'voltpacks')
          saveRecent(system, 'all')
        }}
      >
        <div className="relative grid grid-cols-4 items-center">
          <div className="absolute top-2 right-2 flex">
            {hasSptIntegration(system.kind, system.meta.tenant as Tenant) && isAdminUser && (
              <SptLink id={system.id} includeTitle={false} />
            )}
            {system.kind === BatterySystemKind.Subpack && (
              <Connect
                coreId={system.id}
                id={system.computers ? system.computers[0].id : null}
                includeTitle={false}
                shouldDisplayRemoteAccessLink={shouldDisplayRemoteAccessLink}
              />
            )}
            <EditModalButton id={system.id} includeTitle={false} kind={system.kind} size="small" />
          </div>
          {getSystemImage('card', system?.kind ?? BatterySystemKind.Subpack)}
          <div className="col-span-3 flex-row items-center space-y-2 py-2">
            <h1 className="text-lg font-bold text-black truncate w-3/5">
              {system.displayName ?? system.id}
              {system.kind === BatterySystemKind.Subpack && (
                <DcirStatusBadge coreId={system.id} tenant={system.meta.tenant} />
              )}
            </h1>
            <div className="flex space-x-1  text-sm">
              <ConnectivityInformationBadge
                connectivity={getSystemConnectivityInformation(system)}
                showLastSeen={true}
              />
            </div>
            <div className=" text-gray-500 text-sm">
              <Charge charge={getStateOfCharge(system, isPowerUser)} />
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}
