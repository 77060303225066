import {
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type ChargeValue = number | null

type ChargeProps = {
  charge?: ChargeValue
}

const getChargeProps = (charge?: ChargeValue) => {
  if (charge == null) {
    return {
      className: 'text-gray-500',
      icon: faBatteryQuarter,
    }
  }
  if (charge < 10) {
    return {
      className: 'text-red-1',
      icon: faBatteryQuarter,
    }
  }
  if (charge < 25) {
    return {
      className: 'text-orange',
      icon: faBatteryQuarter,
    }
  }
  if (charge < 50) {
    return {
      className: 'text-powerGreen',
      icon: faBatteryHalf,
    }
  }
  if (charge < 90) {
    return {
      className: 'text-powerGreen',
      icon: faBatteryThreeQuarters,
    }
  }

  return {
    className: 'text-powerGreen',
    icon: faBatteryFull,
  }
}

export function Charge({ charge }: ChargeProps) {
  const { className, icon } = getChargeProps(charge)
  return (
    <div className={className}>
      <FontAwesomeIcon className="mr-2 inline" icon={icon} />
      {charge == null ? 'Unknown' : `${Math.floor(charge)}%`}
    </div>
  )
}
