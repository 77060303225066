import clsx from 'clsx'

import { SizeValue } from '../useSizeScreen'

/**
 * the text-sizes and heights should match in all "line" components
 * (button, datepicker, input, etc)
 */
export function getBoxElementTextSizeClassName(sizeScreen: SizeValue) {
  const className = clsx({
    'text-sm py-1.5': sizeScreen === 'small',
    'text-base py-2': sizeScreen === 'medium',
    'text-lg py-2.5': sizeScreen === 'large',
  })
  return className
}
