import { faChevronDown, faChevronUp, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { Float } from '@headlessui-float/react'
import { Combobox, Listbox } from '@headlessui/react'
import clsx from 'clsx'
import { ChangeEvent, Fragment, MutableRefObject, useCallback, useRef, useState } from 'react'

import { getTestId } from '@northvolt/test-utils'

import {
  BoxButtonElement,
  BoxElementAffixProp,
  BoxInputElement,
  getBoxElementTextSizeClassName,
} from '../Box'
import { BoxInputProps } from '../Box/types'
import { StandardIcon } from '../Icon'
import { Options, OptionsProps } from '../Options'
import getStandardTransitionProps from '../StandardTransition/getStandardTransitionProps'
import { InputState } from '../types'
import { FormFieldWrapper, UseFormFieldProps } from '../useForm'
import { Size, useSizeScreen } from '../useSizeScreen'

export type DropdownValue<ValueExtra = {}> = {
  value: string
  label: string
} & ValueExtra

export type DropdownProps<ValueExtra> = {
  list: DropdownValue<ValueExtra>[]
  label?: string
  placeholder?: string
  size?: Size
  required?: boolean
  tabIndex?: number
  disabled?: boolean
  state?: InputState
  prefix?: BoxElementAffixProp
  search?: boolean
  noResultsMessage?: OptionsProps['noResultsMessage']
  className?: string
  portal?: boolean
  ariaLabel?: string
  // to use auto-focus on a combobox input inside a modal
  inputRefForModal?: MutableRefObject<HTMLInputElement | null>
} & UseFormFieldProps<DropdownValue<ValueExtra>>

export function Dropdown<ValueExtra>({
  label,
  name,
  search,
  noResultsMessage,
  required,
  error,
  placeholder,
  list,
  size,
  tabIndex,
  disabled,
  value,
  state,
  onChange,
  onBlur: onBlurProp,
  prefix,
  className,
  portal,
  ariaLabel,
  inputRefForModal,
}: DropdownProps<ValueExtra>) {
  const [searchString, setSearchString] = useState<string | null>(null)
  // only used if Combobox:
  const [outline, setOutline] = useState<boolean>(false)
  const innerInputRef = useRef<HTMLInputElement | null>(null)
  const inputRef: MutableRefObject<HTMLInputElement | null> = inputRefForModal ?? innerInputRef

  const onSetValue = useCallback(
    (v: string) => {
      const item = list.find((el) => el.value === v)
      if (item != null) {
        onChange?.(item)
        setSearchString(null)
        // un-focus the input box
        inputRef.current?.blur()
        setTimeout(() => {
          // when clicking into one of the options something re-focuses the component
          // this works around it
          inputRef.current?.blur()
        }, 200)
        onBlurProp?.()
      }
    },
    [onChange, onBlurProp, list, inputRef],
  )

  const searchOnChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setSearchString(ev.target.value ?? null)
  }, [])

  const sizeScreen = useSizeScreen(size)

  const commonBoxProps: Omit<
    BoxInputProps,
    'as' | 'value' | 'type' | 'onChange' | 'onBlur' | 'loading' | 'inputRef'
  > = {
    name,
    size: sizeScreen,
    disabled: disabled ?? false,
    tabIndex: tabIndex ?? null,
    error: error ?? null,
    state: state ?? null,
    placeholder: placeholder ?? null,
  }

  const Box: typeof Combobox = search ? Combobox : (Listbox as typeof Combobox)

  const onBlur = () => {
    setOutline(false)
    onBlurProp?.()
  }
  const onFocus = () => {
    setOutline(true)
  }

  return (
    <Box
      disabled={commonBoxProps.disabled || state === 'loading'}
      name={name}
      onChange={onSetValue}
      value={value?.label}
      {...getTestId(name)}
    >
      {({ open }: { open: boolean }) => {
        const SuffixButton = search ? Combobox.Button : Listbox.Button
        // the icon on the right corner of the combobox
        // by using a Combobox.Button if the user clicks on the arrow icon, it will
        // show all options as if it was a non-search dropdown
        let suffix: BoxElementAffixProp = null
        if (state !== 'loading') {
          suffix = (
            <SuffixButton
              className={clsx(
                'flex items-center outline-none -m-4 px-4',
                getBoxElementTextSizeClassName(sizeScreen),
                {
                  'cursor-pointer pointer-events-auto': !commonBoxProps.disabled,
                  'cursor-not-allowed': commonBoxProps.disabled,
                },
              )}
              tabIndex={-1}
            >
              <StandardIcon
                className={clsx({
                  'h-2.5 w-2.5': sizeScreen === 'small',
                  'h-3 w-3': sizeScreen === 'medium',
                  'h-3.5 w-3.5': sizeScreen === 'large',
                })}
                customSize={true}
                icon={open ? faChevronUp : faChevronDown}
                size={sizeScreen}
              />
              {
                // makes this element the same height as BoxElement
                '\u200B'
              }
            </SuffixButton>
          )
        }

        return (
          <FormFieldWrapper
            as={search ? 'Combobox' : 'Listbox'}
            className={className}
            error={error}
            label={label}
            required={required ?? null}
            size={sizeScreen}
          >
            <Float
              {...getStandardTransitionProps()}
              adaptiveWidth
              as="div"
              className="relative w-full"
              floatingAs={portal === false ? Fragment : 'div'}
              offset={1}
              placement="bottom"
              portal={portal ?? true}
              show={!commonBoxProps.disabled && open}
            >
              {search ? (
                // user can search through the items
                <BoxInputElement
                  ariaLabel={ariaLabel}
                  as="Combobox"
                  type="text"
                  {...commonBoxProps}
                  inputRef={inputRef}
                  onBlur={onBlur}
                  onChange={searchOnChange}
                  onFocus={onFocus}
                  outline={outline || open}
                  prefix={
                    prefix != null
                      ? prefix
                      : {
                          icon: faMagnifyingGlass,
                          onClick: () => {
                            inputRef.current?.focus()
                          },
                        }
                  }
                  suffix={suffix}
                  value={searchString ?? value?.label ?? undefined}
                />
              ) : (
                // user can not search through the items
                <BoxButtonElement
                  {...commonBoxProps}
                  as="Listbox"
                  onBlur={onBlur}
                  onFocus={onFocus}
                  outline={open}
                  prefix={prefix}
                  suffix={suffix}
                  value={value?.label}
                />
              )}
              <Options
                as={search ? 'Combobox' : 'Listbox'}
                list={list}
                noResultsMessage={noResultsMessage}
                searchString={searchString}
                size={sizeScreen}
                value={value}
              />
            </Float>
          </FormFieldWrapper>
        )
      }}
    </Box>
  )
}
