import { Float } from '@headlessui-float/react'
import { Menu as HLMenu } from '@headlessui/react'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { getTestId } from '@northvolt/test-utils'

import getStandardTransitionProps from '../StandardTransition/getStandardTransitionProps'

import { MenuItem, MenuItemProps } from './MenuItem'

export type MenuProps = {
  alignMenu?: 'left' | 'right'
  button: ReactNode
  className?: string
  list: MenuItemProps[]
  tabIndex?: number
  testId?: string
}

export function Menu({ alignMenu, button, className, list, testId }: MenuProps) {
  return (
    <HLMenu as="div" className={clsx('whitespace-nowrap relative', className)}>
      {({ open }: { open: boolean }) => (
        <Float
          {...getStandardTransitionProps()}
          adaptiveWidth
          as="div"
          className="relative"
          floatingAs="div"
          offset={1}
          placement="bottom"
          portal={true}
          show={open}
        >
          <HLMenu.Button as="div" {...getTestId(testId)}>
            {button}
          </HLMenu.Button>
          <HLMenu.Items
            className={clsx(
              'z-30 absolute mt-2 min-w-52 overflow-visible bg-white text-black-light rounded-md shadow-lg py-1.5 border border-black border-solid border-opacity-5 focus:outline-none',
              alignMenu === 'right' && 'right-0',
            )}
          >
            {list.map((item, idx) => (
              <MenuItem key={`menuItem-${idx}`} {...item} />
            ))}
          </HLMenu.Items>
        </Float>
      )}
    </HLMenu>
  )
}
