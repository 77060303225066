import { ReactNode } from 'react'

import { SystemFragment } from '@northvolt/gql'
import { Table, TableBody, TableHead, TableHeadCell, TableRow } from '@northvolt/table'

import { SystemCard } from './SystemCard'
import { SystemListRow } from './SystemTableRow'

type HeadingCellProps = { children: ReactNode; className?: string }

function HeadingCell({ children, className }: HeadingCellProps) {
  return (
    <TableHeadCell className={className}>
      <div className="text-lg font-bold">{children}</div>
    </TableHeadCell>
  )
}

export function SystemListBody({ systemList }: { systemList: readonly SystemFragment[] }) {
  return (
    <>
      <div className="lg:block hidden">
        <Table border={false} className="w-full">
          {/* Needs a z-index higher than 1 and lower than 10 to not conflict */}
          {/* with the connectivity icon and the profile dropdown */}
          <TableHead>
            <TableRow>
              <HeadingCell>Title</HeadingCell>
              <HeadingCell>Tags</HeadingCell>
              <HeadingCell>Type</HeadingCell>
              <HeadingCell>Charge</HeadingCell>
              <HeadingCell>Status</HeadingCell>
              <HeadingCell className="flex justify-end">Action</HeadingCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {systemList.map((system) => (
              <SystemListRow key={`${system.id}-${system.kind}`} system={system} />
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="lg:hidden block">
        {systemList.map((system) => (
          <SystemCard key={`${system.id}-${system.kind}`} system={system} />
        ))}
      </div>
    </>
  )
}
