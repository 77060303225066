const macAddressRegex = /^[a-f0-9]{2}(:[a-f0-9]{2}){5}$/
const replaceDashesRegex = /-/g

/**
 * gets the mac-address in the format the backend wants (ff:ff:ff:ff:ff:ff all characters lower-cased)
 * Accepts the following formats (including upper-case characters):
 * eg-ff-ff-ff-ff-ff-ff
 * ff-ff-ff-ff-ff-ff
 * ff:ff:ff:ff:ff:ff
 * if invalid value then return the original value
 */
function getMacAddressFromInput(inputValue: string) {
  let value = inputValue.toLowerCase()
  if (value.startsWith('eg-')) {
    value = value.slice(3)
  }
  value = value.replace(replaceDashesRegex, ':')
  if (!macAddressRegex.test(value)) {
    return inputValue
  }
  return value
}

export const Validate = {
  testMacAddress: (value: string) => macAddressRegex.test(getMacAddressFromInput(value)),
  macAddressAllowedFormats: `Allowed formats: ff:ff:ff:ff:ff:ff, ff-ff-ff-ff-ff-ff or eg-ff-ff-ff-ff-ff-ff`,
  getMacAddressFromInput,
}
