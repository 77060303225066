import {
  IconDefinition,
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { getTestId } from '@northvolt/test-utils'

export type AlertType = 'info' | 'success' | 'warning' | 'error'

export type AlertProps = {
  type?: AlertType
  children: ReactNode
  className?: string
  action?: ReactNode | ReactNode[]
  testId?: string
}

const Icons: Record<AlertType, IconDefinition> = {
  info: faInfoCircle,
  success: faCheckCircle,
  warning: faExclamationTriangle,
  error: faTimesCircle,
}

export function Alert({ action, children, className, type = 'info', testId }: AlertProps) {
  const isInfo = type === 'info'
  const isSuccess = type === 'success'
  const isWarning = type === 'warning'
  const isError = type === 'error'

  return (
    <aside
      {...getTestId(testId)}
      className={clsx(
        'flex flex-row px-4 py-3 gap-4 items-center border rounded w-full relative',
        className,
        {
          'bg-blue-50/10': isInfo,
          'bg-green-50/10': isSuccess,
          'bg-orange-50/10': isWarning,
          'bg-red-50/10': isError,

          'border-blue-100': isInfo,
          'border-green-100': isSuccess,
          'border-orange-100': isWarning,
          'border-red-100': isError,
        },
      )}
    >
      <FontAwesomeIcon
        className={clsx('text-lg', {
          'text-blue-100': isInfo,
          'text-green-100': isSuccess,
          'text-orange-100': isWarning,
          'text-red-100': isError,
        })}
        icon={Icons[type]}
      />
      <div
        className={clsx('flex-1', {
          'text-blue-200': isInfo,
          'text-green-200': isSuccess,
          'text-orange-200': isWarning,
          'text-red-200': isError,
        })}
      >
        {children}
      </div>
      <div className="flex flex-row gap-4 flex-wrap justify-end">{action}</div>
    </aside>
  )
}
