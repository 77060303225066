export const abortSignalTimeoutPolyfill = function () {
  if ('AbortSignal' in window) {
    AbortSignal.timeout =
      AbortSignal.timeout ||
      ((duration) => {
        const controller = new AbortController()
        setTimeout(() => controller.abort(), duration)
        return controller.signal
      })
  }
}
