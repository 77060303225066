import { Transition } from '@headlessui/react'
import { ReactNode } from 'react'

export function Fadein({ children }: { children: ReactNode }) {
  return (
    <Transition
      appear={true}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={true}
    >
      {children}
    </Transition>
  )
}
