import { ReactNode, isValidElement } from 'react'

import { config } from '@northvolt/config'

import { NvError } from './NvError'

let showDebugInformation = false
const env = config.REACT_APP_ENVIRONMENT
if (env === 'development' || env === 'localhost' || env === 'stage') {
  showDebugInformation = true
}

export { showDebugInformation }

/**
 * only shows the error message if the error is an instanceof NvError
 */
export function maskErrorMessage(error: NvError | Error | string | unknown | null) {
  if (error instanceof NvError) {
    return error.message
  } else if (showDebugInformation) {
    if (error instanceof Error) {
      return error.message
    } else {
      return String(error)
    }
  }
  return 'Sorry an unexpected error occurred. A report has been sent.'
}

/**
 * generates a stable ID from a message or an error object
 * message can also be an array of messages
 * useful to be used as keys in react elements or as toast-id for react-toastify
 */
export function computeMessageId(
  message:
    | ReactNode
    | string
    | string[]
    | NvError
    | NvError[]
    | Error
    | Error[]
    // in expressions like
    // } catch(e) {
    // "e" is "unknown", so we handle it here as well
    | unknown
    | null,
  title: string,
) {
  if (isValidElement(message)) {
    return undefined
  }
  let messageArray: unknown[]
  if (!Array.isArray(message)) {
    messageArray = [message as string | NvError]
  } else {
    messageArray = [...message]
  }
  let id = `${title}`
  for (const m of messageArray) {
    if (isValidElement(m)) {
      return undefined
    }
    id += '-'
    if (m instanceof NvError) {
      id += m.message
    } else if (m instanceof Error) {
      // even though we show generic messages for plain JS Errors
      // we can use the JS error message as part of the id, the id is never shown to the user
      id += m.message
    } else if (typeof m === 'string') {
      id += m
    } else {
      id += String(m)
    }
  }
  return id
}
