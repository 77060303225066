import { useState } from 'react'

import { Button } from '@northvolt/form'
import { SystemFragment } from '@northvolt/gql'
import { BaseModal } from '@northvolt/modal'

import { SystemCard } from '../SystemList/SystemCard'

export function ShowAll({
  recentlyViewedSystems,
}: {
  recentlyViewedSystems: readonly SystemFragment[]
}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setIsOpen(true)} size="medium" style="text">
        Show all ({recentlyViewedSystems.length})
      </Button>

      <BaseModal
        onClose={() => {
          setIsOpen(false)
        }}
        visible={isOpen}
      >
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-3 mt-4 text-left">
          {recentlyViewedSystems.map((system) => {
            return <SystemCard key={system.id} system={system} />
          })}
        </div>
      </BaseModal>
    </>
  )
}
