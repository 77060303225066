import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { useEffect } from 'react'

import { Input } from '@northvolt/form'
import { useStorageState } from '@northvolt/store'

import { SystemType } from '../types'

const saveSearchStorageKey = 'jumbotron_search_history'

export function Search({
  search,
  setSearch,
  systemType,
  tabIndex,
}: {
  search: string
  setSearch: (search: string) => void
  systemType: SystemType
  tabIndex?: number
}) {
  const [, setSearchHistory] = useStorageState<string[]>(saveSearchStorageKey, [])

  // debouncing the search input box to not call props.setSearch too often
  // this prevents the API requests from firing too many times
  useEffect(() => {
    let recentlySearchedTimeout: null | ReturnType<typeof setTimeout> = null
    if (search.length >= 3) {
      // to avoid populating the search history with partial search strings ("p", "pa", "pac", "pack" ...)
      // we wait a short amount of time (3 seconds) before persisting the search string.
      // If additional inputs are entered before that interval the setTimeout function will
      // be cleared.
      recentlySearchedTimeout = setTimeout(function () {
        setSearchHistory((previous) => {
          let values = [...previous]
          if (values.length > 10) {
            // keep maximum of 10 elements
            values.shift()
          }
          // removing element if it is already there
          values = values.filter((v) => {
            return v !== search
          })
          // add value to the beginning of the array
          values.unshift(search)
          return values
        })
      }, 3000)
    }
    return () => {
      if (recentlySearchedTimeout != null) {
        clearTimeout(recentlySearchedTimeout)
      }
    }
  }, [search, setSearch, setSearchHistory])

  const placeholder =
    systemType === 'all' ? 'Search the inventory . . .' : `Search for ${systemType} . . .`

  return (
    <Input
      autoFocus
      // we are using @headlessui-float/react adaptiveWidth={true} prop which is experimental
      // in v0.10.0
      // for some reason that prop gets wonky and doesn't work inside the jumbotron if there are more
      // than two elements with floating options
      // see https://headlessui-float.vercel.app/react/adaptive-width.html#adaptive-width-using-js
      // TODO re-enable autocomplete here when we update versions
      // autocomplete={searchHistory}
      className="relative w-full"
      name="search"
      onChange={setSearch}
      placeholder={placeholder}
      prefix={faSearch}
      size="large"
      tabIndex={tabIndex}
      type="text"
      value={search}
    />
  )
}
