import * as Sentry from '@sentry/react'
import { ReactNode } from 'react'

import { ErrorContainer } from './ErrorContainer'
import { toastError } from './toast'

interface ErrorBoundaryProps {
  children?: ReactNode

  /**
   * if not provided use <ErrorContainer error={error} />
   * the provided reset() function triggers props.children to render again
   */
  fallback?:
    | ReactNode
    | ((options: { error: unknown; retry: () => void; title: string }) => ReactNode)

  /**
   * if true will show a toast when an error is caught, defaults to false
   */
  toastErrors?: boolean

  /**
   * A title to include as the heading for toast messages and in sentry reporting
   */
  title: string
}

export function ErrorBoundary({ fallback, children, title, toastErrors }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(_, error) => {
        if (toastErrors) {
          // we can throw an array of errors, Sentry wraps anything that is not instanceof Error
          // into an Error and sets the error.cause as the original value
          // Sentry.ErrorBoundary already reports the errors so we shouldn't report them again inside
          // toastError
          // @ts-ignore
          if (Array.isArray(error.cause)) {
            toastError(
              title,
              // @ts-ignore
              error.cause,
              null,
              { captureException: false },
            )
          } else {
            toastError(title, error, null, { captureException: false })
          }
        }
      }}
      fallback={({ error, resetError }) => {
        if (typeof fallback === 'function') {
          return <>{fallback({ error, title, retry: resetError })}</>
        }
        return (
          <ErrorContainer
            error={error}
            errorType="generic"
            onRetryPressed={resetError}
            showBackButton={true}
            title={title}
          />
        )
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
