import clsx from 'clsx'
import { ComponentProps, ForwardedRef, ReactNode, forwardRef } from 'react'

import { getTestId } from '@northvolt/test-utils'

type TableRowProps = {
  children: ReactNode
  className?: string
  testId?: string
} & ComponentProps<'tr'>

function TableRowInner(
  { children, className, testId, ...restProps }: TableRowProps,
  forwardedRef?: ForwardedRef<HTMLTableRowElement>,
) {
  if (forwardedRef != null) {
    restProps.ref = forwardedRef
  }
  return (
    <tr
      className={clsx('border-solid border-b border-gray-200', className)}
      {...getTestId(testId)}
      {...restProps}
    >
      {children}
    </tr>
  )
}

export const TableRow = forwardRef(TableRowInner)
