import clsx from 'clsx'
import { ReactNode } from 'react'

import { getTestId } from '@northvolt/test-utils'

import { Size, useSizeScreen } from '../useSizeScreen'

export type TagProps = {
  children: ReactNode
  dataTag?: string
  size?: Size
  className?: string
  disabled?: boolean
  // should match values from <Card>
  status?:
    | 'critical'
    | 'warning'
    | 'success'
    | 'info'
    | 'base'
    | 'light-critical'
    | 'light-warning'
    | 'light-success'
    | 'light-base'
  type?: 'outlined' | 'filled'
  testId?: string
}

export function Tag({
  children,
  dataTag,
  size,
  className,
  disabled,
  status = 'base',
  type = 'filled',
  testId,
}: TagProps) {
  const sizeScreen = useSizeScreen(size)
  const base = status.endsWith('base')
  const info = status.endsWith('info')
  const success = status.endsWith('success')
  const warning = status.endsWith('warning')
  const critical = status.endsWith('critical')
  const light = status.startsWith('light')

  return (
    <span
      {...(dataTag && { ['data-tag']: dataTag })}
      {...getTestId(testId)}
      className={clsx(
        'flex items-center w-fit justify-center rounded-full whitespace-nowrap border border-solid',
        className,
        {
          'text-sm py-0.5 px-2.5': sizeScreen === 'small',
          'text-base py-1 px-3.5': sizeScreen === 'medium',
          'text-lg py-1.5 px-5': sizeScreen === 'large',
        },
        // should match colors from <Card>
        { 'opacity-50': light },
        type === 'filled' && {
          'text-white': status !== 'base',
          'border-gray-200 bg-gray-200 text-black-light': base,
          'border-blue-100 bg-blue-100': info,
          'border-green-100 bg-green-100': success,
          'border-orange-100 bg-orange-100': warning,
          'border-red-100 bg-red-100': critical,
        },
        type === 'outlined' && {
          'border-gray-400 text-black-light': base,
          'bg-blue-100/10 border-blue-100 text-blue-200': info,
          'bg-green-100/10 border-green-100 text-green-200': success,
          'bg-orange-100/10 border-orange-100 text-orange-200': warning,
          'bg-red-100/10 border-red-100 text-red-200': critical,
        },
        disabled && 'opacity-70',
      )}
    >
      {children}
    </span>
  )
}
