import clsx from 'clsx'

import { Alert } from '@northvolt/alert'
import { Button } from '@northvolt/form'

// Displays a friendly warning for users accessing the app on the
// soon-to-be-deprecated cloud.northvolt.com URL
// TODO: Delete me once we deem the migration complete...
export function SystemsMigrationNotification() {
  const hostname = window.location.hostname

  const isCloudDev = /cloud\.dev\.northvolt\.com.*/.test(hostname)
  const isCloudProd = /cloud\.northvolt\.com.*/.test(hostname)

  if (!isCloudDev && !isCloudProd) {
    return null
  }

  const redirectHost = isCloudDev ? 'systems.dev.northvolt.com' : 'systems.northvolt.com'

  // redirect to the correct path if the user has navigated to a sub-route
  const path = window.location.pathname

  return (
    <div className={clsx('m-1 w-full md:w-1/2 md:top-0 absolute mx-auto left-0 right-0 top-16')}>
      <Alert
        action={
          <Button onClick={() => window.location.replace(`https://${redirectHost}${path}`)}>
            Go
          </Button>
        }
        className="!bg-orange-25"
        type="warning"
      >
        <span className="font-semibold">We have moved to a new domain.</span>
        <span className="hidden md:inline-block ml-1">
          Please use it going forward and update your bookmarks!
        </span>
      </Alert>
    </div>
  )
}
