import { useEffect, useState } from 'react'

export type SizeValue = 'small' | 'medium' | 'large'

export type SizeScreen = {
  default: SizeValue
  sm?: SizeValue
  md?: SizeValue
  lg?: SizeValue
  xl?: SizeValue
}

export type Size = SizeValue | SizeScreen

type Breakpoint = 'xl' | 'lg' | 'md' | 'sm' | 'xs'
let subscriptions: ((screenSize: Breakpoint) => void)[] = []

function updateBreakpoint() {
  const breakpoint = matches.getBreakpoint()
  for (const subscription of subscriptions) {
    subscription(breakpoint)
  }
}

let timeout: ReturnType<typeof setTimeout>
// the media query values here MUST match the values from tailwind
const xl = window.matchMedia('(min-width: 1280px)')
xl.addEventListener('change', (ev) => {
  matches.xl = ev.matches
  clearTimeout(timeout)
  timeout = setTimeout(updateBreakpoint, 200)
})
const lg = window.matchMedia('(min-width: 1024px)')
lg.addEventListener('change', (ev) => {
  matches.lg = ev.matches
  clearTimeout(timeout)
  timeout = setTimeout(updateBreakpoint, 200)
})
const md = window.matchMedia('(min-width: 768px)')
md.addEventListener('change', (ev) => {
  matches.md = ev.matches
  clearTimeout(timeout)
  timeout = setTimeout(updateBreakpoint, 200)
})
const sm = window.matchMedia('(min-width: 640px)')
sm.addEventListener('change', (ev) => {
  matches.sm = ev.matches
  clearTimeout(timeout)
  timeout = setTimeout(updateBreakpoint, 200)
})

const matches = {
  sm: sm.matches,
  md: md.matches,
  lg: lg.matches,
  xl: xl.matches,
  /**
   * @returns returns the largest matching breakpoint
   */
  getBreakpoint() {
    let breakpoint: Breakpoint = 'xs'
    if (matches.sm) {
      breakpoint = 'sm'
    }
    if (matches.md) {
      breakpoint = 'md'
    }
    if (matches.lg) {
      breakpoint = 'lg'
    }
    if (matches.xl) {
      breakpoint = 'xl'
    }
    return breakpoint
  },
}

/**
 * returns SizeValue with the actual size to use for the component
 * based on the current screen size
 * breakpoints on Size match the Tailwind values
 * @param size the size prop that most form components get
 */
export function useSizeScreen(size: Size = 'medium'): SizeValue {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(() => {
    return matches.getBreakpoint()
  })

  useEffect(() => {
    function screenChange(breakpoint: Breakpoint) {
      setBreakpoint(breakpoint)
    }
    subscriptions.push(screenChange)
    return () => {
      subscriptions = subscriptions.filter((f) => {
        return f !== screenChange
      })
    }
  }, [])

  if (typeof size === 'string') {
    return size
  }
  // all values inside size are optional, we want to get the largest defined
  // breakpoint value that is below the actual CSS media query breakpoint
  switch (breakpoint) {
    case 'xl':
      return size.xl ?? size.lg ?? size.md ?? size.sm ?? size.default ?? 'medium'
    case 'lg':
      return size.lg ?? size.md ?? size.sm ?? size.default ?? 'medium'
    case 'md':
      return size.md ?? size.sm ?? size.default ?? 'medium'
    case 'sm':
      return size.sm ?? size.default ?? 'medium'
    case 'xs':
      return size.default ?? 'medium'
  }
}
