import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import clsx from 'clsx'
import { MouseEvent, useCallback } from 'react'

import { StandardIcon } from '../Icon'
import { OptionsItem } from '../Options'
import { Size, SizeValue, useSizeScreen } from '../useSizeScreen'

import { Tag } from './Tag'

type TagsProps = {
  tags: OptionsItem[]
  onRemove: (value: string) => void
  disabled?: boolean
  size?: Size
}

function TagHandler({
  tagValue,
  tagLabel,
  size,
  onRemove,
}: {
  tagValue: string
  tagLabel: string
  size: SizeValue
  onRemove?: (value: string) => void
}) {
  const onClick = useCallback(
    (ev: MouseEvent<HTMLElement>) => {
      const parentNode = ev.currentTarget?.parentNode?.parentNode as HTMLElement | undefined
      if (parentNode != null) {
        onRemove?.(parentNode.dataset.tag as string)
      }
    },
    [onRemove],
  )

  return (
    <Tag className="mr-2 last:mr-0 mb-1" dataTag={tagValue} size={size}>
      <div
        className={clsx(
          'flex items-center',
          onRemove != null
            ? {
                '-mr-1': size === 'small',
                '-mr-1.5': size === 'medium',
                '-mr-2': size === 'large',
              }
            : {},
        )}
      >
        {tagLabel}
        {onRemove != null && (
          <StandardIcon
            className="text-gray-500"
            icon={faTimes}
            marginLeft={true}
            onClick={onClick}
            size={size}
            testId={`remove-icon-${tagLabel}`}
          />
        )}
      </div>
    </Tag>
  )
}

export function Tags({ tags, onRemove, size, disabled }: TagsProps) {
  const sizeScreen = useSizeScreen(size)
  return (
    <div className="flex mb-1 flex-wrap items-center">
      {tags.map((tag) => {
        let tagValue: string
        let tagLabel: string
        if (typeof tag === 'string') {
          tagValue = tag
          tagLabel = tag
        } else {
          tagValue = tag.value
          tagLabel = tag.label
        }
        return (
          <TagHandler
            key={tagValue}
            onRemove={disabled !== true ? onRemove : undefined}
            size={sizeScreen}
            tagLabel={tagLabel}
            tagValue={tagValue}
          />
        )
      })}
    </div>
  )
}
